<template>
  <el-main>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="权益名称" align="left"></el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200px">
        <template v-slot="{ row }">
          <el-button type="text" @click="edit(row)">编辑</el-button>
          <el-button type="text" @click="del(row.type)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
        list:[],
    };
  },
  created(){
      this.getList();
  },
  methods:{
      getList(){
          this.$axios.post(this.$api.gateway.member.depositList,{
              rows:10000
          }).then(res=>{
              if(res.code == 0){
                  this.list = res.result.list;
              }else{
                  this.$message.error(res.msg);
              }
          })
      },
      edit(row){
        this.$router.push('/member/partnersEquityInfo?type=' + row.type)
      },
      del(type){
        this.$axios.post(this.$api.gateway.member.depositDel,{type}).then(res=>{
          if(res.code == 0){
            this.getList();
            this.$message.success("删除成功");
          }else{
            this.$message.error(res.msg);
          }
        })
      }
  }
};
</script>

<style lang="less" scoped>
    .el-main{
        background: #fff;
    }
</style>